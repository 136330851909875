import React, { useState } from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/react-hooks';
import { useSelector } from 'react-redux';

import $ from '../../../styles/global';
import GoToClassButton from './GoToClassButton';
import CreateClassButton from './CreateClassButton';
import GoToFormButton from './GoToFormButton';
import { getClassGQL } from '../../../apollo/instructor';
import Loading from '../../Loading';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const InnerContainer = styled.div`
  width: 550px;
  margin-top: 128px;
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: ${$.layout().margin5}px;
`;

const Title = styled.div`
  font-size: 30px;
  color: ${$.color.orange4};
  text-align: center;
  letter-spacing: -1px;
  text-transform: uppercase;
  font-family: Ruda Bold;
  margin-bottom: ${$.layout().margin4}px;
`;

const Option = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  margin-bottom: ${$.layout().margin5}px;

  > * {
    margin-right: 3%;
    &:last-child {
      margin-right: 0;
    }
  }
`;

const Status = styled.div`
  > * {
    &:first-child {
      color: ${$.color.blue5};
      font-family: Ruda Bold;
      text-transform: uppercase;
      padding-bottom: ${$.layout().padding1}px;
      border-bottom: 1px solid ${$.color.gray2};
      margin-bottom: ${$.layout().margin2}px;
    }
  }
`;

const StyledCreateClassButton = styled(CreateClassButton)`
  ${({ disabled }) => {
    return disabled
      ? `pointer-events: none; opacity: 0.5; background-color: ${$.color.gray2};`
      : '';
  }}
`;

const StyledGoToClassButton = styled(GoToClassButton)`
  ${({ disabled }) => {
    return disabled
      ? `pointer-events: none; opacity: 0.5; background-color: ${$.color.gray2};`
      : '';
  }}
`;

/**
 * Dashboard for instructor.
 * 1. Pings getClass to check if instructor has a class going on. This is the loading state. A loading
 * component is shown.
 * 2. getClass returns true. Shows buttons and a notice saying "There is a class going on". Disable
 * Create Class button.
 * 3. getClass returns false. Shows buttons but notice will say "No class ongoing.". Disable Go To Class
 * button.
 */
const Dashboard = () => {
  const state = useSelector(({ user }) => {
    return { user };
  });
  const [classState, setClassState] = useState(false);
  const [loading, setLoading] = useState(true);

  useQuery(getClassGQL, {
    variables: {
      instructorId: state.user.id,
    },
    onCompleted: ({ instructor }) => {
      setLoading(false);
      setClassState(instructor !== null);
    },
    onError: () => {
      setLoading(false);
    },
  });

  return (
    <Container>
      <InnerContainer>
        <Title>Instructor Dashboard</Title>
        {loading ? (
          <LoadingContainer>
            <Loading />
          </LoadingContainer>
        ) : (
          <>
            <Option>
              <StyledCreateClassButton disabled={classState} />
              <StyledGoToClassButton disabled={!classState} />
              <GoToFormButton />
            </Option>
            <Status>
              <div>Notice</div>
              <div>
                {classState
                  ? 'You have an ongoing class now.'
                  : 'No ongoing class found.'}
              </div>
            </Status>
          </>
        )}
      </InnerContainer>
    </Container>
  );
};

export default Dashboard;

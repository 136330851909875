import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../../../styles/global';
import AddPersonIcon from '../../../assets/icons/add-person.svg';
import StyledLink from './StyledLink';

const GoToForm = styled(StyledLink)`
  background-color: ${$.color.blue4};

  &:hover {
    color: ${$.color.blue4};
    border: 2px solid ${$.color.blue4};
    > svg {
      fill: ${$.color.blue4};
    }
  }
`;

const text = 'Add student to registry';

const GoToFormButton = ({ className }) => {
  return (
    <GoToForm to="/instructor/new/" title={text} className={className}>
      <AddPersonIcon />
      <div>{text}</div>
    </GoToForm>
  );
};

GoToFormButton.defaultProps = {
  className: '',
};

GoToFormButton.propTypes = {
  className: PropTypes.string,
};

export default GoToFormButton;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import $ from '../../../styles/global';
import SchoolIcon from '../../../assets/icons/school.svg';
import StyledLink from './StyledLink';

const GoToClass = styled(StyledLink)`
  background-color: ${$.color.orange2};

  &:hover {
    color: ${$.color.orange2};
    border: 2px solid ${$.color.orange2};
    > svg {
      fill: ${$.color.orange2};
    }
  }
`;

const text = 'Go to class';

const GoToClassButton = ({ className }) => {
  return (
    <GoToClass to="/instructor/class/" title={text} className={className}>
      <SchoolIcon />
      <div>{text}</div>
    </GoToClass>
  );
};

GoToClassButton.defaultProps = {
  className: '',
};

GoToClassButton.propTypes = {
  className: PropTypes.string,
};

export default GoToClassButton;
